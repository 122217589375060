import { useSelector } from "react-redux";
import axios from "axios";
import { createAlert } from "components/Alert/Alert";
import { api_oracle } from "config/api_host";
import { useNavigate } from "react-router-dom";

export const useOracleAxiosWithAuth = () => {
  const token = useSelector((state) => state.auth?.token);
  const navigate = useNavigate();

  const apiOracle = axios.create({
    baseURL: api_oracle, // Oracle API URL
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  apiOracle.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            createAlert("Warning", "Invalid request: Check your inputs.");
            console.error("Invalid request: Check your inputs.");
            break;
          case 401:
            createAlert("Warning", "Unauthorized: Please ensure you're logged in.");
            console.error("Unauthorized: Please ensure you're logged in.");
            break;
          case 404:
            if (error.response.data.type) {
              createAlert(error.response.data.type, error.response.data.message);
            }
            console.error("No data found", 404);
            break;
          case 422:
            error.response.data.errors.forEach((error) => {
              createAlert("Warning", error.msg);
            });
            break;
          default:
            console.error(error.response.status);
        }
      } else {
        createAlert("Warning", "Unable to connect to the server. Please check your internet connection.");
        console.error("Unable to connect to the server. Please check your internet connection.");
      }
      return Promise.reject(error);
    }
  );

  return apiOracle;
};
