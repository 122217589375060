// store/reducers/archReducer.js
import { createSlice } from "@reduxjs/toolkit";

const getArchDataFromLocal = () => {
  if (sessionStorage.getItem("archData")) {
    return JSON.parse(sessionStorage.getItem("archData"));
  }
  return {
    arch_id: "",
    branch_name: "",
    engineer_number: "",
    full_name: "",
    father_name: "",
    mother_name: "",
    gender: "",
    date_of_birth: "",
    graduation_date: "",
    join_date: "",
    central_number: "",
    home_branch_name: "",
  };
};

export const archSlice = createSlice({
  name: "arch",
  initialState: getArchDataFromLocal(),
  reducers: {
    setArchData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearArchData: () => {
      sessionStorage.removeItem("archData");
      return {
        arch_id: "",
        branch_name: "",
        engineer_number: "",
        full_name: "",
        father_name: "",
        gender: "",
        date_of_birth: "",
        graduation_date: "",
        join_date: "",
        central_number: "",
        home_branch_name: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setArchData, clearArchData } = archSlice.actions;

export default archSlice.reducer;