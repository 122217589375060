import { createSlice } from "@reduxjs/toolkit";
import { decryptData, encryptData } from "../../config/lib";

const getUserInfoFromLocal = () => {
  if (sessionStorage.getItem("userInfo"))
    return JSON.parse(decryptData(sessionStorage.getItem("userInfo")));
  else return { role: "guest", token: null };
  // else return { role: "admin", token: null };
  // else return { role: "user", token: null };
};

export const authSlice = createSlice({
  name: "auth",
  initialState: getUserInfoFromLocal(),
  reducers: {
    login: (state, action) => {
      sessionStorage.setItem(
        "userInfo",
        encryptData(JSON.stringify(action.payload))
      );
      
      // state.access = action.payload.access;
      // state.refresh = action.payload.refresh;

      state.eng_id = action.payload.eng_id;
      state.token = action.payload.access;

      state.role = action.payload.role;
      state.username = action.payload.username;
      state.user_id = action.payload.user_id;
      // state.email = action.payload.email;
      // state.phone = action.payload.phone;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
    },
    logout: (state) => {
      sessionStorage.removeItem("userInfo");

      // state.access = null;
      // state.refresh = null;

      state.eng_id = null;
      state.token = null;

      state.role = "guest";
      // state.email = null;
      // state.phone = null;
      state.username = null;
      state.user_id = null;
      state.first_name = null;
      state.last_name = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
