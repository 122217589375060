import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Error404({ navigateTo, timer = null }) {
  const navigate = useNavigate();
  useEffect(() => {
    let timeOut;

    if (timer != null) {
      timeOut = setTimeout(() => {
        navigate(navigateTo);
      }, 10000);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [timer, navigateTo, navigate]);
  return (
    <div className="flex self-start justify-center items-center w-full md:p-2 mt-5 ">
      <section className="w-full max-w-5xl p-6 flex flex-col items-center gap-5">
        <h1 className="text-9xl text-red-500 dark:text-red-600">404</h1>
        <div>
          <h5
            className="text-xl font-medium text-gray-600 sm:text-2xl dark:text-white"
            style={{ direction: "ltr" }}
          >
            {("Oops! Page not found.")}
          </h5>

          <h5
            className="text-lg font-semibold text-gray-600 dark:text-white text-center"
            style={{ direction: "ltr" }}
          >
            {("return to")}{" "}
            <Link to={navigateTo} className="text-blue-500 hover:underline">
              {("home")} {("page")}
            </Link>
          </h5>
        </div>
      </section>
    </div>
  );
}
