// sidebar 
import { CgMenuLeft } from "react-icons/cg";
import { useSelector } from 'react-redux';
import {
  FcImport,
  FcSupport,
  FcWorkflow,
  FcBusinessman,
  FcHome,
  FcMoneyTransfer,
  FcCalculator,
  FcPaid,
  FcDocument, FcIdea, FcContacts, FcDiploma1, FcAddressBook,FcEditImage,FcViewDetails,FcDataConfiguration,FcApproval
} from "react-icons/fc";
import "./Sidebar.scss";
import GroupLinks from "./sidebarCompo/SidebarGroupLinks/SidebarGroupLinks.component";
import { type } from "@testing-library/user-event/dist/type";


export default function Sidebar({ handleStateSidebar }) {

  const engInfo = useSelector((state) => state.engInfo);
  const arch_brno = engInfo.arch_brno;

  const listLinks = [
    {
      title: "الرئيسية",
      url: "/dashboard",
      icon: FcHome,
      role: ["any"],
    },
    {
      title: "الذاتية",
      url: "/dashboard/personal",
      icon: FcAddressBook,
      role: ["any"],
      type: "list",
      content: [
        // {
        //   title: "المهندس",
        //   url: "/dashboard/personal/eng",
        //   icon: FcIdea,
        //   count: 0,
        // },
        {
          title: "الهوية الشخصية",
          url: "/dashboard/personal/prsn",
          icon: FcBusinessman,
          count: 0,
        },
        {
          title: "البطاقة النقابية",
          url: "/dashboard/personal/union",
          icon: FcContacts,
          count: 0,
        },
        {
          title: "المعلومات الأكاديمية",
          url: "/dashboard/personal/info",
          icon: FcDiploma1,
          count: 0,
        },
        {
          title: "تعديل الذاتية",
          url: "/dashboard/personal/edit-appeal",
          icon: FcViewDetails,
          count: 0,
        },
        {
          title: "الطلبات المقدمة",
          url: "/dashboard/appeals",
          icon: FcApproval,
          count: 0,
        },
      ],
    },
    {
      title: "البطاقة المالية",
      url: "/finance",
      icon: FcMoneyTransfer,
      role: ["any"],
      type: "list",
      content: [
        {
          title: "المزاولات",
          url: "/dashboard/practices",
          icon: FcMoneyTransfer,
          count: 0,
        },
        // {
        //   title: "السنوات المثبتة",
        //   url: "/dashboard/fixed",
        //   icon: FcMoneyTransfer,
        //   count: 0,
        // },
        // {
        //   title: "السنوات غير المثبتة",
        //   url: "/dashboard/not-fixed",
        //   icon: FcMoneyTransfer,
        //   count: 0,
        // },
      ],
    },
    {
      title: "العائدات التقاعدية",
      url: "/yields",
      icon: FcCalculator,
      role: ["any"],
      type: "list",
      content: [
        {
          title: "طلب تثبيت مزاولة",
          url: "/dashboard/yields/fix-req",
          icon: FcCalculator,
          count: 0,
        },
        {
          title: "احتساب عائدات",
          url: "/dashboard/yields/calculation",
          icon: FcCalculator,
          count: 0,
        },
      ],
    },
    {
      title: "الرسوم",
      url: "/fees",
      icon: FcPaid,
      role: ["any"],
      type: "list",
      content: [
        {
          title: "دفع الرسوم",
          url: `https://pay.eng-homs.sy/?eng=${arch_brno}`,
          icon: FcMoneyTransfer,
          count: 0,
          blank:true
        },
      ],
    },
    {
      title: "الوثائق المطلوبة",
      url: "/dashboard/documents",
      icon: FcDocument,
      role: ["any"],
    },
  ];

  const listLinks2 = [
    {
      title: "الاعدادات",
      url: "/dashboard/settings",
      icon: FcSupport,
      role: ["any"],
    },
    { title: "تسجيل الحروج", url: "/logout", icon: FcImport, role: ["any"] },
  ];

  const sidebarState = useSelector((state) => state.layout.layoutState);
  const { username, first_name, last_name } = useSelector(
    (state) => state.auth
  );

  // Conditionally add the username menu item if the user is logged in and username is available
  const listLinks2_with_username = username
    ? [
      {
        title: `${first_name} ${last_name} (${username})`,
        url: "/dashboard/profile",
        icon: FcBusinessman,
        role: ["any"],
      },
      ...listLinks2,
    ]
    : listLinks2;

  return (
    <>
      {sidebarState == "openSmall" ? (
        <div
          className="fixed top-0 left-0 z-30 w-full h-full bg-gray-900/50 right-0"
          onClick={() => handleStateSidebar()}
        ></div>
      ) : null}
      <aside
        className={`${sidebarState} fixed top-0 ltr:left-0 rtl:right-0-0 z-40 lg:w-64 md:w-16 w-0 h-full duration-150 sidebar`}
      >
        <div
          className="grid grid-cols-1 h-full md:px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 sidebar-content"
          style={{ gridTemplateRows: "min-content auto min-content" }}
        >
          <div
            className={`w-full flex md:flex-col lg:flex-row items-center justify-between overflow-hidden  sidebar-head`}
          >
            <button
              className="text-xl hover:bg-gray-300 hover:text-white_ rounded-md p-2 duration-200 text-gray-600 dark:text-gray-300 dark:hover:bg-blue-600"
              onClick={handleStateSidebar}
            >
              <CgMenuLeft />
            </button>
            <h1 className="text-4xl font-extrabold mb-1 w-full flex mr-4 ltr:ml-4 items-center gap-3 text-red-600 dark:text-blue-600">
              <div className=" text-gray-700 w-full text-nowrap font-bold text-xl dark:text-white">
                <h5>نقابة المهندسين</h5>
              </div>
            </h1>
          </div>
          <GroupLinks list={listLinks} />
          <GroupLinks list={listLinks2_with_username} />
        </div>
      </aside>
    </>
  );
}
